/* eslint-disable no-console */
import sha256 from 'crypto-js/sha256'
import {
  nonce as nonceQuery,
  login as loginQuery,
  logout as logoutQuery,
  getAgent as agent,
  gLogin as loginGoogle,
  fbLogin as fbLogin,
  gNativeLogin as gNativeLogin
} from '@graphql/auth.graphql'

export const getNonce = async apolloClient => {
  try {
    const resp = await apolloClient.mutate({
      mutation: nonceQuery,
    })
    return resp.data
  } catch (error) {
    console.log(error)
    throw new Error(error)
  }
}

export const getAgent = async apolloClient => {
  try {
    const resp = await apolloClient.query({
      query: agent,
    })
    return resp.data
  } catch (error) {
    console.log(error)
    throw new Error(error)
  }
}

export const login = async (apolloClient, { email, password }) => {
  try {
    const nonceResponse = await getNonce(apolloClient)
    const { nonce } = nonceResponse
    const agentResponse = await getAgent(apolloClient)
    const agentId = agentResponse.agent.id
    const hashedPassword = sha256(password).toString()
    const passwordData = sha256(`${agentId}${hashedPassword}${nonce.data}`).toString()
    const resp = await apolloClient.mutate({
      mutation: loginQuery,
      variables: {
        email,
        password: passwordData,
        nonce: nonce.id,
      },
    })
    return resp.data
  } catch (error) {
    console.log(error)
    throw new Error(error)
  }
}

export const logout = async apolloClient => {
  try {
    const resp = await apolloClient.mutate({
      mutation: logoutQuery,
    })
    return resp.data
  } catch (error) {
    console.log(error)
    throw new Error(error)
  }
}

export const googleLogin = async (apolloClient,  { variables, options = {} } ) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: loginGoogle,
      variables,
      ...options,
    })
    return resp.data
  } catch (error) {
    console.log(error)
    throw new Error(error)
  }
}

export const faceLogin = async (apolloClient, { variables, options = {} }) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: fbLogin,
      variables: {
        userId: variables.userID,
        accessToken: variables.accessToken,
        expiresIn: variables.expiresIn,
        oneSignalUserId: variables.oneSignalUserId,
      },
    })
    return resp.data
  } catch (error) {
    throw new Error(error)
  }
}

export const googleNativeLogin = async (apolloClient,  { variables, options = {} }) => {
  try {
    const { errors, data } = await apolloClient.mutate({
      mutation: gNativeLogin,
      variables: {
        googleId: variables.sub,
        firstName: variables.given_name,
        lastName: variables.family_name,
        email: variables.email,
        verified: Boolean(variables.email_verified),
        oneSignalUserId: variables.oneSignalData.oneSignalUserId,
      },
    });
    return data
  } catch (error) {
    throw new Error(error)
  }
}