import { sendRecover } from '@graphql/session.graphql'

export const sendRecoverService = async (apolloClient, {
  variables: { email, gre },
  options,
}) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: sendRecover,
      variables: { email, gre },
      ...options,
    })
    return resp.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error)
  }
}
