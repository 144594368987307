import sha256 from  'crypto-js/sha256'
import { signUp as signUpQuery } from '@graphql/auth.graphql'

// eslint-disable-next-line max-len
export const signUp = async (apolloClient,  { gre, name, lastName, email, phone, owner, birth, password, agreeTerms }) => {
  try {
    const resp = await apolloClient.mutate({
      mutation: signUpQuery,
      variables: {
        gre,
        firstName: name,
        lastName,
        email,
        phone,
        owner,
        birthDate: birth,
        password,
        agreeTerms,
      },
      //...options,
    })
    
    return resp.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    throw new Error(error)
  }
}
