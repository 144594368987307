import useAuth from './useAuth'
import useSignUp from './useSignUp'

export default {
  useAuth,
  useSignUp,
}

export {
  useAuth,
  useSignUp,
}
