import { useState, useEffect } from 'react'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { ArrowRight, ErrorOutlineOutlined, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import owasp from 'owasp-password-strength-test'
import sha256 from 'crypto-js/sha256'

import { useGlossary } from '@features/App/hooks'
import ProgressBar from './ProgressBar'
import PasswordField from './PasswordField'

const useStyles = makeStyles(theme => ({
  listItemIcon: {
    minWidth: '15px',
  },
  success: {
    color: theme.palette.primary.main,
    padding: '0px 16px',
  },
  failed: {
    color: 'rgba(255,118,81,1)',
    padding: '0px 16px',
  },
  inherit: {
    color: 'inherit',
    padding: '0px 16px',
  },
  success1: {
    color: theme.palette.primary.main,
    padding: '0px',
    '& .MuiTypography-body1': {
        fontSize: '14px'
    }
  },
  failed1: {
      color: 'rgba(255,118,81,1)',
      padding: '0px',
      '& .MuiTypography-body1': {
          fontSize: '14px'
      }

  },
  inherit1: {
      color: 'inherit',
      padding: '0px',
  }
}))

const Label = styled('p')(({ theme }) => ({
  color: theme.palette.gray[700],
  fontSize: '12px',
  margin: '0px',
}))


const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))
const PasswordFieldEditor = ({onChange, showSugg=true , ...props}) => {

  const { glossary } = useGlossary()
  const classes = useStyles()

  const [strength, setStrength] = useState(0)
  const [owaspResult, setOwaspResult] = useState(null)
  const [password, setPassword] = useState('')
  const [valid, setValid] = useState(false)
  const [open, setOpen] = useState(false)
  
  const handleClick = (event) => {
    setOpen(!open)
  }

  useEffect(() => {
    const owaspResult = owasp.test(password)
    const valid = owaspResult.requiredTestErrors.length === 0
    const passwordData = sha256(password).toString()
    let strength = 0;
    if (password.length > 0) {
        if (password.length > 5) strength += 20;
        //At least 10 chars
        if (owaspResult.passedTests.includes(0)) strength += 20;
        //No repeated chars
        if (owaspResult.passedTests.includes(2)) strength += 20;
        // At least one lowercase letter
        if (owaspResult.passedTests.includes(3)) strength += 10;
        // At least one uppercase letter
        if (owaspResult.passedTests.includes(4)) strength += 10;
        // At least one number
        if (owaspResult.passedTests.includes(5)) strength += 10;
        // At least one symbol
        if (owaspResult.passedTests.includes(6)) strength += 10;
        if (!valid && strength >= 60) strength = 50;
    }
    setOwaspResult(owaspResult)
    setStrength(strength)
    setValid(valid)
    onChange({
        valid, password: passwordData, empty: password.length === 0,
        ...owaspResult
    });
}, [password, setValid, setStrength, setOwaspResult]);

  return <Container>
  <PasswordField value={password} onChange={setPassword}/>
  <ProgressBar value={strength}/>
  <Label>
    {glossary('Password2')} {password.length === '0' ? '' : 
    (strength === 100 ? glossary('Password3') : (strength >= 80 ? glossary('Password4') : (strength >= 60 ? glossary('Password5') : glossary('Password6'))))
    }
  </Label>
  { showSugg && <List>
    <ListItem 
     className={owaspResult ? (owaspResult.passedTests.includes(0) ? classes.success : classes.failed) : classes.failed}>
      <ListItemIcon
      className={`${classes.listItemIcon} ${owaspResult ? (owaspResult.passedTests.includes(0) ? classes.success : classes.failed) : classes.inherit}`}
      >
        {owaspResult ? (owaspResult.passedTests.includes(0) ? <CheckRoundedIcon/> : <ClearRoundedIcon/>) :
          <ArrowRight/>} 
      </ListItemIcon>
      <ListItemText primary={glossary('Password7')}/>
    </ListItem>
    <ListItem className={owaspResult ? (owaspResult.passedTests.includes(2) ? classes.success : classes.failed) : classes.inherit}>
      <ListItemIcon
                      className={`${classes.listItemIcon} ${owaspResult ? (owaspResult.passedTests.includes(2) ? classes.success : classes.failed) : classes.inherit}`}>
                      {owaspResult ? (owaspResult.passedTests.includes(2) ? <CheckRoundedIcon/> : <ClearRoundedIcon/>) :
                          <ArrowRight/>}
      </ListItemIcon>
      <ListItemText primary={glossary('Password8')}/>
    </ListItem>
    <Collapse in={open}>
      <List component='div'>
        <ListItem className={owaspResult ? (owaspResult.passedTests.includes(3) ? classes.success : classes.inherit) : classes.inherit}>
          <ListItemIcon className={`${classes.listItemIcon} ${owaspResult ? (owaspResult.passedTests.includes(3) ? classes.success : classes.inherit) : classes.inherit}`}>
          {owaspResult ? (owaspResult.passedTests.includes(3) ? <CheckRoundedIcon/> : <ClearRoundedIcon/>) :
                                <ArrowRight/>}
          </ListItemIcon>
          <ListItemText primary={glossary('Password9')}/>
        </ListItem>
        <ListItem
            className={owaspResult ? (owaspResult.passedTests.includes(4) ? classes.success : classes.inherit) : classes.inherit}>
            <ListItemIcon
                       className={`${classes.listItemIcon} ${owaspResult ? (owaspResult.passedTests.includes(4) ? classes.success : classes.inherit) : classes.inherit}`}>
                      {owaspResult ? (owaspResult.passedTests.includes(4) ? <CheckRoundedIcon/> : <ClearRoundedIcon/>) :
                    <ArrowRight/>}
              </ListItemIcon>
            <ListItemText primary={glossary('Password10')}/>
        </ListItem>
        <ListItem
          className={owaspResult ? (owaspResult.passedTests.includes(5) ? classes.success : classes.inherit) : classes.inherit}>
              <ListItemIcon
                className={`${classes.listItemIcon} ${owaspResult ? (owaspResult.passedTests.includes(5) ? classes.success : classes.inherit) : classes.inherit}`}>
                  {owaspResult ? (owaspResult.passedTests.includes(5) ? <CheckRoundedIcon/> : <ClearRoundedIcon/>) :
                  <ArrowRight/>}
             </ListItemIcon>
            <ListItemText primary={glossary('Password11')}/>
        </ListItem>
        <ListItem
            className={owaspResult ? (owaspResult.passedTests.includes(6) ? classes.success : classes.inherit) : classes.inherit}>
              <ListItemIcon
                className={`${classes.listItemIcon} ${owaspResult ? (owaspResult.passedTests.includes(6) ? classes.success : classes.inherit) : classes.inherit}`}>
                    {owaspResult ? (owaspResult.passedTests.includes(6) ? <CheckRoundedIcon/> : <ClearRoundedIcon/>) :
                        <ArrowRight/>}
              </ListItemIcon>
            <ListItemText primary={glossary('Password12')}/>
          </ListItem>
      </List>
  </Collapse>
  <ListItem onClick={handleClick} sx={{ cursor: 'pointer'}}>
                <ListItemIcon>
                    <ErrorOutlineOutlined/>
                </ListItemIcon>
                <ListItemText primary={open ? glossary('Password13') : glossary('Password14')}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
  </ListItem>
  </List>}
  </Container>
}

export default PasswordFieldEditor
