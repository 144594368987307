/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import { signUpEvent } from '@helpers/GA4Events'
import { signUp } from '../services/signUp'

export default function useSignUp() {
  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const userSignUp = useCallback( variables => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await signUp(apolloClient, variables)
        setLoading(false)
        if(response?.signUp?.session) signUpEvent('password')
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    userSignUp,
  }
}
