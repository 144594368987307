/* eslint-disable arrow-body-style */
/* eslint-disable no-async-promise-executor */
import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import { loginEvent } from '@helpers/GA4Events'
import { login, googleLogin, faceLogin, googleNativeLogin } from '../services/auth'
import { sendRecoverService } from '../services/sendRecover'
import { newPasswordService } from '../services/newPassword'

export default function useAuth() {
  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const userLogin = useCallback( variables => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await login(apolloClient, variables)
        if(response?.login) loginEvent('password')
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const googleUserLogIn = useCallback( variables => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await googleLogin(apolloClient, { variables } )
        if(response?.gLogin) loginEvent('google')
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const googleNativeUserLogIn = useCallback( variables => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await googleNativeLogin(apolloClient, { variables } )
        if(response?.gNativeLogin) loginEvent('google')
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const fbUserLogin = useCallback( variables => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await faceLogin(apolloClient, { variables })
        if(response?.fbLogin) loginEvent('facebook')
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const sendPasswordRecover = useCallback( (variables, options) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await sendRecoverService(apolloClient, {
          variables,
          options,
        })
        resolve(response.sendRecover)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const newPassword = useCallback( (variables, options) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await newPasswordService(apolloClient, {
          variables,
          options,
        })
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    userLogin,
    googleUserLogIn,
    fbUserLogin,
    sendPasswordRecover,
    newPassword,
    googleNativeUserLogIn,
  }
}
