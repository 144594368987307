import { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import { useGlossary } from '@features/App/hooks'


const StyledTextField = styled(props => <TextField {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.gray[100],
  fontWeight: 500,
  borderRadius: theme.spacing(0.5),
  width: '100%',
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.gray[300]}`,
    '&.Mui-error': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  '& input': {
    border: 'none',
    font: theme.font.roboto.paragraph.medium,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  '& fieldset': {
    display: 'none',
  },
}))

const PasswordField = ({onChange, value, ...props}) => {

  const { glossary } = useGlossary()

  const [show, setShow] = useState(false)
  

  return <>
  <StyledTextField size='small' {...props} 
    type={show ? 'text' : 'password'}
    value={value} 
    autoComplete={'current-password'}
    onChange={e => onChange(e.target.value)}
    InputProps={{
      endAdornment: (
        <InputAdornment position='end'>
          <IconButton
            aria-label='toggle password visibility'
            onClick={() => setShow(!show)}
            edge='end'
          >
            {show ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
    }}/>
  </>
}

export default PasswordField
