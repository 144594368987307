import { styled } from '@mui/material/styles'
import { LinearProgress } from '@mui/material'

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  backgroundColor: theme.palette.gray[200],
  borderRadius: '6px',
  marginTop: '6px',
  height: '3px',
  width: '100%',
  transition: 'background 500ms',
  '& .MuiLinearProgress-bar': {
      transition: 'background 500ms, width 500ms',
      background: 'linear-gradient(90deg, rgba(255,118,81,1) 0%, rgba(255,255,89,1) 100% )',
  },
  '&.completed': {
    backgroundColor: '#e6e6e6',
        borderRadius: '6px',
        marginTop: '6px',
        height: '3px',
        width: '100%',
        transition: 'background 500ms',
        '& .MuiLinearProgress-bar': {
            transition: 'background 500ms, width 500ms',
            background: 'linear-gradient(90deg, rgba(153,255,5,1) 100%)',
        }
  }
}))

const ProgressBar = ({value = 100, ...props}) => {
  
    return <CustomLinearProgress variant={"determinate"} value={value} className={value !== 100 ? 'completed': ''} {...props}/>
}

export default ProgressBar
